import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Img from 'gatsby-image';
import { graphql, Link } from 'gatsby';
import HeroCarousel from '../components/carousel/hero-carousel';

const Exchanges1031 = ({ data }) => {
    const { heroImage, hyve1, hyve2, hyve3, jade, west, entrada, farms, banner } = data;

    return (
        <Layout>
            <SEO title="Private Client" />
            <div className="page-1031">
                <section className="page-1031-hero no-container" data-component="hero">
                    {/* <Img
                        fluid={heroImage.childImageSharp.fluid}
                        alt={''}
                        className="page-1031-hero__background"
                    /> */}
                    <div className="container">
                        <h1 className="heading1">IDEAL PRIVATE CLIENT</h1>
                        <Link className="btn btn-primary" to="/contact">
                            Book a consultation
                        </Link>
                    </div>
                </section>

                <section className="page-1031-text">
                    <h2>IDEAL Private Client</h2>
                    <p>Retain control, give up responsibilities.</p>
                    <div className="page-1031-text__content">
                        <h3>Passive, Hands-Off Investing </h3>

                        <p>
                            You earned your success, now it is time to enjoy it. Let IDEAL handle
                            the day-to-day management of your real estate so you can spend time
                            doing what you desire.{' '}
                        </p>

                        <h3>You Retain Control </h3>

                        <p>
                            Any major decisions are yours to make. We will report on our performance
                            to budget. We will recommend changes to capital expenditures, business
                            plan changes, and refinance or sale opportunities. Our Investor
                            Relations team is accessible seven days a week and can be reached
                            directly by phone.{' '}
                        </p>

                        <h3>Institutional, At-Scale Management </h3>

                        <p>
                            With IDEAL Private Client, you receive the same high-end, quality asset
                            management and reporting that we give to our publicly traded
                            institutional partners.{' '}
                        </p>

                        <h3>Complex Estate & Tax Planning, Simplified </h3>

                        <p>
                            From drop and swap partnership splits to minority interest estate
                            planning tools, we will work with your CPAs and attorneys to implement
                            the same tax efficient, generational wealth planning tools that we use
                            ourselves.{' '}
                        </p>

                        <h3>Diverse Market Expertise </h3>

                        <p>
                            We operate a vast multi-billion dollar portfolio across the Western
                            United States, giving us expertise in a variety of urban, suburban, and
                            tertiary markets in multiple dynamic regions.{' '}
                        </p>

                        <h3>100% of Net Cash Flow & Equity Growth </h3>

                        <p>
                            With IDEAL Private Client, your property works for you, not us. You keep
                            100% of all net cash flow and equity growth. We do not promote off your
                            capital. Our fee structure is flat, fair, and transparent.{' '}
                        </p>

                        <h3>Track Record of Success </h3>

                        <p>
                            We have achieved an average full-cycle annual internal rate of return of
                            more than 30%. We have never failed a 1031 exchange and are trusted by
                            hundreds of investors nationwide for continued high-level performance
                        </p>
                    </div>
                </section>

                <section className="page-1031-client-2">
                    <h2>IDEAL Private Client Case Study</h2>
                    <p>Retain control, give up responsibilities.</p>
                    <div className="page-1031-client-2__content">
                        <div>
                            <h3 className="page-1031-client-2__content-title">
                                Jade, Entrada, Upper West, & Village at Gracy Farms
                            </h3>
                            <p className="page-1031-client-2__content-description">
                                Exchanged 313 Units of Old Section 8 Housing Into 974 Units of Class
                                A Properties
                            </p>
                            <div className="page-1031-client-2__comparison">
                                <div className="page-1031-client-2__comparison-col">
                                    <h4 className="page-1031-client-2__comparison-title">
                                        Before Exchange
                                    </h4>
                                    <ul>
                                        <li>Cash flow: $4.8M</li>
                                        <li>Depreciation: $1M</li>
                                        <li>Asset Quality: C-</li>
                                        <li>Location: B</li>
                                        <li>Asset Value: $223M</li>
                                        <li>313 Units</li>
                                    </ul>
                                    <p className="page-1031-client-2__comparison-deprecation">
                                        2021 DEPRECIATION
                                    </p>
                                    <p className="page-1031-client-2__comparison-price">$1.1M</p>
                                </div>
                                <div className="page-1031-client-2__comparison-col">
                                    <h4 className="page-1031-client-2__comparison-title">
                                        After Exchange
                                    </h4>
                                    <ul>
                                        <li>Cash flow: $5.5M</li>
                                        <li>Depreciation: $17M</li>
                                        <li>Asset Quality: A</li>
                                        <li>Location: A</li>
                                        <li>Asset Value: $354M</li>
                                        <li>974 Units</li>
                                    </ul>
                                    <p className="page-1031-client-2__comparison-deprecation">
                                        2022/2023 DEPRECIATION
                                    </p>
                                    <p className="page-1031-client-2__comparison-price">$17M</p>
                                </div>
                            </div>
                            <p className="page-1031-client-2__long-description">
                                IDEAL facilitated the sale of Kings Village Apartments in Pasadena,
                                California, a 1971 constructed affordable housing apartment with
                                millions in deferred maintenance. The client wanted to diversify
                                their holdings, increase cash flow, increase depreciation, and
                                increase their units owned. Through its extensive brokerage
                                relationships and direct relationships with owners, IDEAL was able
                                to successfully 1031 exchange Kings Villages into four assets (974
                                units) across California, Nevada, Utah, and Texas, increasing cash
                                flow by $700,000 and deprecation tax savings by more than $16M.
                                IDEAL currently manages Jade, Entrada, Upper West, and Village at
                                Gracy Farms for the client, providing monthly cash flow and
                                quarterly updates.
                            </p>
                        </div>
                        <div className="page-1031-client-2__carousel">
                            <HeroCarousel
                                items={[jade, west, entrada, farms].map((img) => {
                                    return {
                                        image: {
                                            imageFile: {
                                                ...img,
                                            },
                                        },
                                    };
                                })}
                            />
                        </div>
                    </div>
                </section>
                <section className="page-1031-contact">
                    <h2>Contact Us</h2>
                    <div className="page-1031-contact__grid">
                        <div>
                            <h3>Mailing Address</h3>
                            <p>3128 Willow Ave., Suite 101 Clovis, CA 93612</p>
                        </div>
                        <div>
                            <h3>Email Address</h3>
                            <p>
                                <a href="mailto:kevin@idealcapgroup.com">kevin@idealcapgroup.com</a>
                            </p>
                        </div>

                        <div>
                            <h3>Phone Number</h3>
                            <p>
                                <a href="tel:559-347-6980">(559) 347-6980</a>
                            </p>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    );
};

export default Exchanges1031;

export const query = graphql`
    query {
        hyve1: file(relativePath: { in: "1031-hyve-1-optimized.jpeg" }) {
            childImageSharp {
                fluid(maxWidth: 700, maxHeight: 700) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        hyve2: file(relativePath: { in: "1031-hyve-2-optimized.jpeg" }) {
            childImageSharp {
                fluid(maxWidth: 700, maxHeight: 700) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        hyve3: file(relativePath: { in: "1031-hyve-3-optimized.jpeg" }) {
            childImageSharp {
                fluid(maxWidth: 700, maxHeight: 700) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        jade: file(relativePath: { in: "1031-jade-optimized.jpeg" }) {
            childImageSharp {
                fluid(maxWidth: 700, maxHeight: 700) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        west: file(relativePath: { in: "1031-west-optimized.jpeg" }) {
            childImageSharp {
                fluid(maxWidth: 700, maxHeight: 700) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        farms: file(relativePath: { in: "1031-farms-optimized.jpeg" }) {
            childImageSharp {
                fluid(maxWidth: 700, maxHeight: 700) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        entrada: file(relativePath: { in: "1031-entrada-optimized.jpeg" }) {
            childImageSharp {
                fluid(maxWidth: 700, maxHeight: 700) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        heroImage: file(relativePath: { in: "1031-banner.webp" }) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        banner: file(relativePath: { in: "1031-new-banner.webp" }) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;
